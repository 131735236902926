import React, {useState} from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-bootstrap";
import colors from "../colors";

const ReviewContainer = styled.section`
  padding: 40px 20px;
  background-color: #ffffff;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: bold;
`;

const CarouselWrapper = styled.div`
  position: relative;
  max-width: 650px;
  margin: 0 auto;
`;

const StyledCarousel = styled(Carousel)`
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  }

  .carousel-control-prev {
    left: -40px;
  }

  .carousel-control-next {
    right: -40px;
  }

  .carousel-indicators button {
    filter: invert(0.25);
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(0.5);
  }

  @media (max-width: 768px) {
    .carousel-control-prev {
      left: -10px;
    }

    .carousel-control-next {
      right: -10px;
    }
  }
`;

const Review = styled.div`
  padding: 20px;
  background-color: ${colors.offWhite};
  margin: 0 auto;
  max-width: 600px;
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 300px;
  }
`;

const Stars = styled.div`
  margin-bottom: 15px;
`;

const ReviewText = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
  font-style: italic;
`;

const Reviewer = styled.h4`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`;

const StyledStarIcon = styled(FontAwesomeIcon)`
  color: #FFD700;
  font-size: 20px;
  margin: 0 2px;
`;

const SeeMoreButton = styled.a`
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: ${colors.primaryOrange};
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.secondaryOrange};
  }
`;

const Reviews = () => {
	const [reviews, setReviews] = useState([{
		relativePublishTimeDescription: "2 months ago",
		text: {
			text: "Example Review"
		},
		rating: 5,
		authorAttribution: {
			profilePhotoUrl: "",
			displayName: "Example",
			isAnonymous: false
		},
		//Link to the review on google maps
		googleMapsUri: ""
	}]);
	fetch("https://api.tirrell.studio/cleanchoice/reviews")
		.then(async (resp) => {
			if(resp.ok){
				setReviews(JSON.parse(await resp.text()))
			}
		});
  return (
    <ReviewContainer>
      <Title>What Our Clients Say</Title>
      <CarouselWrapper>
        <StyledCarousel indicators={false}>
          {reviews.map((review, index) => (
            <Carousel.Item key={index}>
              <Review>
                <Stars>
                  {[...Array(review.rating)].map((_, i) => (
                    <StyledStarIcon key={i} icon={faStar} />
                  ))}
                </Stars>
                <ReviewText>"{review.text.text}"</ReviewText>
                <Reviewer>- {review.authorAttribution.displayName}</Reviewer>
              </Review>
            </Carousel.Item>
          ))}
        </StyledCarousel>
      </CarouselWrapper>
      <SeeMoreButton
        href="https://search.google.com/local/reviews?placeid=ChIJz0ajem4TEYgRDPeWqND_hYM&q=Clean+Choice+Pressure+Washing&hl=en&gl=US"
        target="_blank"
        rel="noopener noreferrer"
      >
        See More Reviews
      </SeeMoreButton>
    </ReviewContainer>
  );
};

export default Reviews;
